import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	ContentSection,
	HeaderSection,
	InlineMessage,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { FormElementWrapper } from "../form-element-wrapper/form-element-wrapper";
import { InputField } from "../input-field/input-field";
import { TextArea } from "../text-area/text-area";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { ActionBar } from "../../actions-and-controls/action-bar/action-bar";
import { Form } from "./form";

export const Meta: DocPageMeta = {
	category: "Forms and input",
	title: "Form",
	notepad: "https://hackmd.io/FnjdMiLCR4awS2LdFkCGCQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Form"
			subTitle="A container that collects and submits user input through various interactive elements."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Form} />
				<Header.H2>Examples</Header.H2>
			</TextContainer>
			<TextContainer article>
				<Header.H3>Basic Usage</Header.H3>
				<Paragraph>
					A form is a group of related input controls that allows users to provide data or configure
					options. When using the <Code>Form</Code> component, be sure to wrap your input controls
					with the <Code>Form Element Wrapper</Code> component to get consistent styling and access
					to features like label, help and error messages, invalid and disabled states, etc.
				</Paragraph>
				<Paragraph> These elements typically include:</Paragraph>
				<Ul
					items={[
						<>
							<b>Labels</b>: Clearly identify each input field's purpose with concise, descriptive
							labels. Position labels left aligned and above the input field by default for better
							readability and accessibility.
						</>,
						<>
							<b>Data Inputs</b>: Include various structured input types like{" "}
							<GatsbyLink to="/lab/components/Forms and input/Input Field">Input Field</GatsbyLink>{" "}
							, <GatsbyLink to="/lab/components/Forms and input/Checkbox">Checkbox</GatsbyLink> ,{" "}
							<GatsbyLink to="/lab/components/Forms and input/Radios">Radios</GatsbyLink> ,{" "}
							<GatsbyLink to="/lab/components/Forms and input/Select">Select</GatsbyLink>,{" "}
							<GatsbyLink
								to="/lab/components/Actions and controls/Period
							picker"
							>
								Period Picker
							</GatsbyLink>
							, etc. Check the individual component guidelines for specific usage recommendations.
						</>,
						<>
							<b>Help Text</b>: Provide contextual guidance (e.g., tooltips, placeholders, helper
							text) to assist users in providing the correct information. Place help text near the
							relevant input field for easy reference.
						</>,
						<>
							<b>Action Bar</b>: Enable users to take action, such as submitting the form or
							resetting input values. Include primary and secondary action buttons as needed.
						</>,
					]}
				/>
			</TextContainer>
			<Example fn={BasicExample} />
			<TextContainer article>
				<Header.H3>Submit Form Example</Header.H3>
				<Paragraph>
					Enhance the basic form with designated areas for submit. If possible, always include a
					"Cancel" button for users to exit.
				</Paragraph>
			</TextContainer>

			<Example fn={SubmitExample} />
			<TextContainer article>
				<Header.H3>Submit Form - Slim Example</Header.H3>
				<Paragraph>
					A more compact version of the Submit Form, ideal for limited space (e.g{" "}
					<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink>) or when the form has
					fewer fields.
				</Paragraph>
			</TextContainer>

			<Example fn={SubmitSlimExample} />
			<TextContainer article>
				<Header.H3>Submit Form - Horizontal Example</Header.H3>
				<Paragraph>
					Optimizes readability by aligning labels horizontally alongside the corresponding input
					fields. This layout is particularly useful for forms with shorter labels or when space is
					constrained.
				</Paragraph>
			</TextContainer>

			<Example fn={SubmitHorizontalExample} />
			<TextContainer article>
				<Header.H2>Properties</Header.H2>
			</TextContainer>

			<Knobs component={Form} initialProps={{}} />
			<TextContainer article>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Form</Code> when
					</Paragraph>
					<Ul
						items={[
							"Collecting multiple pieces of user data or configuring multiple options.",
							"A structured, standardized approach to input collection is desired.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>Form</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink> (Main content
								area): Typically used as the central focus of a page or section.
							</>,
							<>
								<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink>: Effective for
								collecting focused information without disrupting the main content flow.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>Form</Code> to existing components for visual consistency.
							</>,
							<>
								<b>Optional vs. Mandatory</b>:
								<Ul
									items={[
										<>Clearly indicate required fields with "(required)" or an asterisk (*).</>,
										<>
											If most fields are optional, only mark required fields. If most are required,
											mark optional fields.
										</>,
									]}
								/>
							</>,
							<>
								<b>Grouping & Alignment</b>:
								<Ul
									items={[
										"Group related fields together (e.g., name, address).",
										"Align the left edges of input fields for a clean visual flow.",
									]}
								/>
							</>,
							<>
								<b>Length</b>:
								<Ul
									items={[
										"Keep forms concise and focused on essential information.",
										"For long forms, consider using progressive disclosure (show/hide sections as needed).",
									]}
								/>
							</>,
						]}
					/>
					<Header.H4>Interaction </Header.H4>
					<Ul
						items={[
							<>
								<b>User Control</b>:
								<Ul
									items={[
										<>
											Always include a "<b>Cancel</b>" button for modal forms or a clear way to
											exit/discard changes.
										</>,
										<>
											Support "<b>Undo</b>" and "<b>Redo</b>" functionality if applicable.
										</>,
									]}
								/>
							</>,
							<>
								<b>Clear Labeling and Help Text</b>:
								<Ul
									items={[
										"Use descriptive labels and avoid relying solely on placeholder text.",
										"Provide clear instructions and inline help if needed.",
										"Avoid excessive placeholder texts.",
									]}
								/>
							</>,
							<>
								<b>Validation and Feedback</b>:
								<Ul
									items={[
										"Validate input in real-time if possible.",
										"Provide clear and specific error messages near the relevant fields.",
										"Use visual cues (e.g., icons, colors) to indicate the status of each field (valid, invalid, etc.).",
									]}
								/>
							</>,
							<>
								<b>Form submission and error handling</b>:
								<Ul
									items={[
										"Review before submission: Allow users to review their input before submitting to minimize errors.",
										<>
											Clear feedback: Use visual cues or messages (e.g.,{" "}
											<GatsbyLink to="/lab/components/Feedback/Toast">Toast</GatsbyLink>) to
											indicate successful completion or errors during submission.
										</>,
									]}
								/>
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								You have a simple input with minimal validation. Use{" "}
								<GatsbyLink to="/lab/components/Forms and input/Input Field">
									Input Field
								</GatsbyLink>{" "}
								instead.
							</>,
							"You want more control over the styling. If you need complete control over the look and feel of your form, you might prefer using individual input elements and styling them yourself.",
							"You're not submitting data to a server. If you're only collecting data for client-side use and don't need to send it to a server, a Form component might not be necessary.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							<>
								<b>Label Placement</b>: Place labels close to their associated input fields. Avoid
								large gaps, especially in horizontal layouts, as this can confuse users of screen
								readers and those with visual impairments.
							</>,
							<>
								<b>Screen Reader Focus</b>: Remember that screen readers primarily focus on the text
								within the active input field. Ensure clear and accurate labels so users understand
								the context without relying on surrounding text.
							</>,
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Use sentence case for labels, instructions, and error messages.",
							"Keep labels brief (1-3 words) but descriptive enough to convey the purpose of the field.",
							"Provide clear and concise instructions or help text to guide users.",
							"Write informative error messages that clearly explain the issue and how to correct.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicExample = () => {
	const [value, setValue] = useState("");
	const [value1, setValue1] = useState("");
	const onCancel = () => {
		setValue("");
		setValue1("");
	};
	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		alert("submitting");
	};
	return (
		<>
			<Form heading="Form heading" onSubmit={onSubmit}>
				<FormElementWrapper name="Basic input" label="Basic label">
					<InputField
						aria-label="My accessible input component"
						placeholder="Placeholder"
						value={value}
						onChange={setValue}
					/>
				</FormElementWrapper>
				<FormElementWrapper name="Basic text area" label="Basic label">
					<TextArea
						aria-label="My accessible textarea component"
						value={value1}
						onChange={setValue1}
					/>
				</FormElementWrapper>
				<ActionBar
					primary={{
						children: "Save",
						type: "submit",
					}}
					cancel={{ children: "Cancel", type: "reset", onClick: onCancel }}
					noPadding
					noBackground
				/>
			</Form>
		</>
	);
};

const SubmitExample = () => {
	const [value, setValue] = useState("");
	const [value1, setValue1] = useState("");
	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		alert("submitting");
	};
	return (
		<Form heading="Form heading" onSubmit={onSubmit}>
			<FormElementWrapper name="Basic input" label="Basic label">
				<InputField
					aria-label="My accessible input component"
					placeholder="Placeholder"
					value={value}
					onChange={setValue}
				/>
			</FormElementWrapper>
			<FormElementWrapper name="Basic text area" label="Basic label">
				<TextArea
					aria-label="My accessible textarea component"
					value={value1}
					onChange={setValue1}
				/>
			</FormElementWrapper>
			<ActionBar
				primary={{
					children: "Primary",
					type: "submit",
				}}
				noPadding
				noBackground
			/>
		</Form>
	);
};

const SubmitSlimExample = () => {
	const [value, setValue] = useState("");
	const [value1, setValue1] = useState("");
	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		alert("submitting");
	};
	return (
		<Form variant="slim" heading="Form heading" onSubmit={onSubmit}>
			<FormElementWrapper name="Basic input" label="Basic label">
				<InputField
					aria-label="My accessible input component"
					placeholder="Placeholder"
					value={value}
					onChange={setValue}
				/>
			</FormElementWrapper>
			<FormElementWrapper name="Basic text area" label="Basic label">
				<TextArea
					aria-label="My accessible textarea component"
					value={value1}
					onChange={setValue1}
				/>
			</FormElementWrapper>
			<ActionBar
				primary={{
					children: "Primary",
					type: "submit",
				}}
				noPadding
				noBackground
			/>
		</Form>
	);
};

const SubmitHorizontalExample = () => {
	const [value, setValue] = useState("");
	const [value1, setValue1] = useState("");
	const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		alert("submitting");
	};
	return (
		<Form variant="horizontal" heading="Form heading" onSubmit={onSubmit}>
			<FormElementWrapper name="Basic input" label="Basic label">
				<InputField
					aria-label="My accessible input component"
					placeholder="Placeholder"
					value={value}
					onChange={setValue}
				/>
			</FormElementWrapper>
			<FormElementWrapper name="Basic text area" label="Basic label">
				<TextArea
					aria-label="My accessible textarea component"
					value={value1}
					onChange={setValue1}
				/>
			</FormElementWrapper>
			<ActionBar
				primary={{
					children: "Primary",
					type: "submit",
				}}
				noPadding
				noBackground
			/>
		</Form>
	);
};
